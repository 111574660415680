<template>
  <div class="change-pwd-modal">
    <a-modal
      title="修改密码"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :rules="rules"
      >
        <a-form-model-item label="旧密码" prop="oldPwd">
          <a-input v-model="form.oldPwd" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPwd">
          <a-input v-model="form.newPwd" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import md5 from 'md5';

export default {
  name: 'ChangePwdModal',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        oldPwd: '',
        newPwd: '',
      },
      rules: {
        oldPwd: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur',
          },
        ],
        newPwd: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      })
    },
    handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          const { err, res } = await this.$api.admin.changePassword({
            oldPwd: md5(this.form.oldPwd),
            newPwd: md5(this.form.newPwd),
          });
          if (!err) {
            this.$message.success(res.msg);
            this.$refs.form.resetFields();
            this.visible = false;
          }
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
