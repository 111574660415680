<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <a-layout-sider v-model="collapsed" collapsible>
      <div class="logo" v-if="collapsed">PiNews</div>
      <div class="logo" v-else>PiNews Admin</div>
      <a-menu
        theme="dark"
        :default-selected-keys="['/console']"
        mode="inline"
        v-model="selectedKey"
        @select="onMenuSelect"
      >
        <template v-for="(menu, index) in menus">
          <!-- 一级 -->
          <a-menu-item v-if="!menu.children" :key="menu.path">
            <a-icon :type="menu.meta.icon" />
            <span>{{ menu.meta.menuName }}</span>
          </a-menu-item>
          <!-- 多级 -->
          <a-sub-menu v-else :key="index">
            <span slot="title">
              <a-icon :type="menu.meta.icon" />
              <span>{{ menu.meta.menuName }}</span>
            </span>
            <template v-for="subMenu in menu.children">
              <a-menu-item
                v-if="
                  subMenu.meta.showMenu !== false &&
                  hasPermission(subMenu.meta.permission)
                "
                :key="subMenu.path"
              >
                {{ subMenu.meta.menuName }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <div class="user">
          <a-icon class="user-icon" type="smile" theme="filled" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              Hi, {{ _userInfo && _userInfo.username }} <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="onChangePwdClick">修改密码</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="onLogoutClick">退出登录</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content class="main">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item v-for="(bread, index) in breadcrumb" :key="index">
            {{ bread }}
          </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="content">
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        pinews.app ©2023 Developed by
        <a href="mailto:nicebaiqing@gmail.com">nicebaiqing@gmail.com</a>
      </a-layout-footer>
      <!-- 修改密码 -->
      <ChangePwdModal ref="changePwdModal" />
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { PERMISSION } from '@/config/const.js';
import ChangePwdModal from '@/components/ChangePwdModal.vue';

export default {
  name: 'Admin',
  components: { ChangePwdModal },
  data() {
    return {
      collapsed: false,
      selectedKey: [],
    };
  },
  computed: {
    ...mapGetters('user', ['_userInfo']),
    menus() {
      return this.$router.options.routes[1].children;
    },
    breadcrumb() {
      return this.$route.matched
        .slice(1, this.$route.matched.length)
        .map((item) => item.meta.menuName);
    },
  },
  mounted() {
    this.selectedKey = [this.$route.path];
    // console.log(
    //   this.$route.matched
    //     .slice(1, this.$route.matched.length)
    //     .map((item) => item.meta.menuName)
    //     .join('/')
    // );
  },
  methods: {
    onMenuSelect({ key }) {
      this.$router.push({
        path: key,
      });
    },
    // 点击退出登录
    onLogoutClick() {
      this.$store.commit('user/logout');
      this.$router.push({
        path: '/login',
      });
    },
    // 判断是否有权限
    hasPermission(permission) {
      if (permission === undefined) return true;
      return permission.some((item) => {
        return this._userInfo.permission === PERMISSION[item].value;
      });
    },
    // 修改密码
    onChangePwdClick() {
      this.$refs.changePwdModal.open();
    },
  },
};
</script>

<style lang="less" scoped>
#components-layout-demo-side .logo {
  height: 32px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px 8px;
  text-align: center;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
}
.ant-layout-sider {
  background: #4d0c8b;
}
::v-deep .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #4d0c8b;
  .ant-menu-inline.ant-menu-sub {
    background: #3c096c;
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: #9148a3;
}
::v-deep .ant-layout-sider-children {
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    .anticon {
      font-size: 18px;
    }
  }
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 60px;
  line-height: 60px;
}
.header {
  background: #fff;
  padding: 0;
  border-bottom: #ebedef solid 1px;
  display: flex;
  justify-content: flex-end;
  .user {
    margin-right: 20px;
    display: flex;
    align-items: center;
    .user-icon {
      color: #1890ff;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
.main {
  overflow-y: scroll;
  height: calc(100vh - 133px);
  padding: 0 16px;
  .content {
    padding: 24px;
    background: #fff;
    min-height: calc(100% - 53px);
  }
}
</style>
